<template>
  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
            <a-form layout="inline">
              <!-- <a-form-model-item>
                <a-input v-model="page.key"  placeholder="请输入关键字"></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.company_id" placeholder="请选择公司">
                  <a-select-option v-for="(item, index) in companyList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.admin_id" placeholder="请选择销售">
                  <a-select-option v-for="(item, index) in salesList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item> -->
              <a-form-model-item>
                <a-button type="primary" @click="openAddModel" icon="plus"
                  >新增</a-button
                >
              </a-form-model-item>
            </a-form>
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table
                :columns="columns"
                :data-source="list"
                rowKey="id"
                bordered
                :pagination="false"
              >
                <template slot="is_top" slot-scope="text, record">
                  <a-tag color="green" v-if="text == '2'">是</a-tag>
                  <a-tag color="orange" v-if="text == '1'">否</a-tag>
                </template>
                <template slot="action" slot-scope="text, record">
                  <a-dropdown>
                    <a
                      class="ant-dropdown-link"
                      @click.stop.prevent="(e) => e.preventDefault()"
                    >
                      更多操作
                      <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-button
                          type="link"
                          @click="openEditModel(record.id)"
                          icon="edit"
                          >编辑</a-button
                        >
                      </a-menu-item>
                      <a-menu-item>
                        <a-button
                          type="link"
                          @click="delConfirm(record.id)"
                          icon="delete"
                          >删除</a-button
                        >
                      </a-menu-item>
                      <a-menu-item>
                        <a-button
                          type="link"
                          @click="goMenuManager(record.id)"
                          icon="setting"
                          >菜单管理</a-button
                        >
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>
              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal
      title="平台管理"
      :width="600"
      :destroyOnClose="true"
      :confirmLoading="$store.state.requestLoading"
      :visible="actionVisible"
      @ok="actionData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
    >
      <a-form-model
        ref="formModel"
        :rules="formModelRules"
        :model="formModel"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="名称" prop="name">
          <a-input
            v-model="formModel.name"
            placeholder="请输入平台名称"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="是否顶级平台" prop="is_top">
          <a-radio-group v-model="formModel.is_top">
            <a-radio value="2"> 是 </a-radio>
            <a-radio value="1"> 否 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="header内容" prop="header_value">
          <a-input
            v-model="formModel.header_value"
            type="textarea"
            :rows="2"
            placeholder="请输入header内容"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="次级平台表" prop="table_name">
          <a-input
            v-model="formModel.table_name"
            placeholder="请输入次级平台表名称"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="content">
          <a-input
            v-model="formModel.content"
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
import axios from "axios";

export default {
  name: "index",
  data() {
    return {
      actionVisible: false,
      formModel: {
        id: "",
        name: "",
        is_top: "",
        header_value: "",
        content: "",
        table_name: "",
      },
      formModelRules: {
        name: [
          { required: true, message: "请输入平台名称", trigger: "change" },
        ],
        is_top: [
          { required: true, message: "请选择平台类型", trigger: "change" },
        ],
        header_value: [
          { required: true, message: "请输入header内容", trigger: "change" },
        ],
        table_name: [
          {
            required: true,
            message: "请输入次级平台表名称",
            trigger: "change",
          },
        ],
      },
      columns: [
        {
          title: "平台名称",
          dataIndex: "name",
        },
        {
          title: "是否顶级平台",
          dataIndex: "is_top",
          scopedSlots: { customRender: "is_top" },
        },
        {
          title: "header内容",
          dataIndex: "header_value",
          width: 300,
        },
        {
          title: "次级平台表名称",
          dataIndex: "table_name",
          width: 150,
        },
        {
          title: "备注",
          dataIndex: "content",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 200,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 150,
          scopedSlots: { customRender: "action" },
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
      },
      recordPage: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
      },
      list: [],
      salesList: [],
      actionVisible: false,
    };
  },
  mounted() {
    this.getList();
    // this.getSalesList();
  },
  methods: {
    /**
     * 打开新增弹框
     * @author wwq
     * */
    openAddModel() {
      this.resetForm();
      this.actionVisible = true;
    },
    // 清空表单
    resetForm() {
      for (let key in this.formModel) {
        this.formModel[key] = "";
      }
    },
    // 关闭新增编辑弹框
    cancel() {
      this.actionVisible = false;
    },
    /**
     * 打开编辑弹框
     * @author wwq
     * */
    async openEditModel(id) {
      let res = await Api.Detail({ id: id });
      this.formModel = res.target;
      this.actionVisible = true;
    },
    /**
     * 添加，更新
     * @author wheat
     */
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    // 删除
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        content: "您确定要删除吗?",
        async onOk() {
          let res = await Api.Delete({ id: id });
          if (res && res.code == "0") {
            that.$message.success(res.message);
            that.getList();
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },

    /**
     * 获取列表
     * @author wheat
     * */
    async getList() {
      let res = await Api.List(this.page);
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },
    /**
     * 分页切换
     * @author wheat
     * */
    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    goMenuManager(id) {
      // this.$router.push('/system-config/platform-menu/' + id);
      this.$router.push({
        path: "/platform-menu",
        name: "菜单管理",
        params: {
          platform_id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
