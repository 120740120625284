
import request from '@/utils/request';

// 列表
export function List(params) {
  return request({
    url: '/admin/collocation/platform/page',
    method: 'GET',
    params
  });
}


// 新增
export function Save(data) {
  return request({
    url: '/admin/collocation/platform/save',
    method: 'POST',
    data
  });
}

// 更新
export function Update(data) {
  return request({
    url: '/admin/collocation/platform/update',
    method: 'POST',
    data
  });
}

// 详情
export function Detail(params) {
  return request({
    url: '/admin/collocation/platform/detail',
    method: 'GET',
    params
  });
}

// 删除
export function Delete(params) {
  return request({
    url: '/admin/collocation/platform/delete',
    method: 'GET',
    params
  });
}